import PropTypes from "prop-types";
import useMySrcmModel from "./useMySrcmModel";

const useMySrcmEvent = ({ name, methodParams, client }) =>
  useMySrcmModel({
    api: name ? `/api/v3/events/${name}/` : "",
    methodParams,
    client,
  });
useMySrcmEvent.propTypes = {
  name: PropTypes.string.isRequired,
  methodParams: PropTypes.shape({}),
  client: PropTypes.string,
};
useMySrcmEvent.defaultProps = {
  methodParams: {},
  client: "eventsClient",
};

export default useMySrcmEvent;
