import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import {
  hideParticipantsPanel,
  resetParticipantsPanel,
  resetSubmitParticipants,
} from "gatsby-plugin-hfn-profile/state/actions/participantsPanel";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import useMySrcmModel from "gatsby-plugin-hfn-profile/components/SrcmModels/useMySrcmModel";
import {
  receiveSrcmGResultDoc,
  receiveSrcmGSameStayDoc,
  receiveSrcmGStayResultDoc,
} from "gatsby-plugin-hfn-profile/state/actions/receiveSrcmResultDoc";
import handleError from "sites-common/utils/handleError";
import { computeRule } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import useEventInfo from "gatsby-plugin-hfn-profile/components/PnrSummary/useEventInfo";
import { updateTransportDetails } from "gatsby-plugin-hfn-profile/components/PnrSummary/useMySrcmPnr";

const useMySrcmGlobalPnr = ({ pnr }) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [error3, setError] = useState(null);
  const addTransportData = useRef({});

  const {
    data,
    isLoaded,
    errorMessage,
    refresh: doRefresh,
    cacheKey,
  } = useMySrcmModel({
    api: `/api/v3/pnrs/${pnr}/`,
    client: "eventsClient",
  });
  const recsData = useMemo(
    () =>
      data?.stays
        ?.filter((r) => r.pnr === pnr && r.status !== "cancelled")
        ?.map((r) => ({ ...r, ...r.stay_json })) || [],
    [data, pnr]
  );
  const stays = useMemo(
    () => (isLoaded ? recsData || [] : []),
    [isLoaded, recsData]
  );
  const registrants = isLoaded ? data?.registrations : [];

  const events = registrants?.map((r) => r.event_name);
  const eventId = events ? events[0] : "";
  const { customConfig } = useEventInfo(eventId);
  const { transport_restrictions, ashram_id_for_stay } = customConfig;

  const loading = !isLoaded;
  const error = errorMessage ? "Error in Fetching Stay Registrations" : error3;
  const sameStay = data?.same_stay;

  const dispatch = useDispatch();

  useEffect(() => {
    addTransportData.current = {
      stays: stays && stays.length > 0 ? stays[stays.length - 1] : {},
      transport_restrictions,
      sameStay,
      ashram_id_for_stay,
    };
  }, [stays, transport_restrictions, sameStay, ashram_id_for_stay]);

  const updateSameStay = useCallback(
    (ss) => {
      if (ss !== addTransportData.current?.sameStay) {
        return fetchSrcmApi({
          api: `/api/v3/pnrs/${pnr}/`,
          methodParams: {
            same_stay: ss,
          },
          client: "eventsClient",
          method: "PATCH",
        })
          .then(() => {
            dispatch(receiveSrcmGSameStayDoc(cacheKey, ss));
          })
          .catch(() => {});
      }
      return null;
    },
    [fetchSrcmApi, pnr, cacheKey, dispatch]
  );

  const registerNewStays = useCallback(
    (pnr2, meditationCenterId, visitors, p2data) => {
      if (!(visitors && visitors.length > 0)) {
        return;
      }

      const methodParams = visitors.map((p0) => {
        return {
          pnr: pnr2,
          communication_preference: p0.email ? 1 : 0,
          meditation_center: meditationCenterId,
          ...p0,
          stay_json: {
            ...p2data,
          },
        };
      });

      fetchSrcmApi({
        api: `/api/v3/ashram-stays/`,
        methodParams,
        client: "eventsClient",
        method: "POST",
      })
        .then((data2) => {
          if (!!data2 && data2.length > 0) {
            data2.forEach((r) => {
              dispatch(receiveSrcmGStayResultDoc(cacheKey, r.id, r));
            });
          } else {
            dispatch(
              showAlert({
                title: "Error",
                message: "Something went wrong. Please try again later.",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            showAlert({
              title: "Error",
              message: "Something went wrong. Please try again later.",
            })
          );
        });
    },
    [dispatch, fetchSrcmApi, cacheKey]
  );

  const updateReg1 = useCallback(
    (registrationId, patchBody) => {
      return fetchSrcmApi({
        api: `/api/v3/ashram-stays/${registrationId}/`,
        methodParams: {
          ...patchBody,
          log: "Updating",
          stay_json: {
            ...patchBody.stay_json,
          },
          meditation_center:
            typeof patchBody.meditation_center === "object"
              ? patchBody.meditation_center.id
              : patchBody.meditation_center,
        },
        client: "eventsClient",
        method: "PATCH",
      })
        .then((data2) => {
          if (!!data2 && !!data2.status) {
            dispatch(receiveSrcmGStayResultDoc(cacheKey, data2.id, data2));
          } else {
            setError("Error in Updating Registrations");
          }
        })
        .catch(() => {
          setError("Error in Updating");
        });
    },
    [fetchSrcmApi, setError, cacheKey, dispatch]
  );

  const updateReg = useCallback(
    (person, onS, onF) => {
      updateReg1(person.id, person).then(onS).catch(onF);
    },
    [updateReg1]
  );

  const cancelRegs = useCallback(
    (listIds) => {
      listIds.forEach((personId) => {
        updateReg1(personId, {
          status: "cancelled",
          cancelled: new Date().valueOf() % 2147483647,
        });
      });
    },
    [updateReg1]
  );

  const eventRegisterNew = useCallback(
    (pnr2, eventId2, persons, p2data, computePnrPageUrl, cb) => {
      const defaultRef = "B99999999";
      const childRef = "B99999998";

      const methodParams = persons.map((p0) => {
        let x = {};

        if (p0.ref === defaultRef || p0.ref === childRef) {
          x = {
            gender: p0.gender,
            age_group: p0.age_group,
            city_id: p0.city_id,
            ref: p0.ref,
          };
          if (p0.email) {
            x.email = p0.email;
          }
          if (p0.mobile) {
            x.mobile = p0.mobile;
          }
        } else {
          if (p0?.extras) {
            Object.entries(p0.extras).map(([key, val]) => {
              if (val) {
                x[key] = val;
              }
              return null;
            });
          }
          x.partner_id = p0.id;
        }

        return {
          pnr: pnr2,
          // XXX - hardcoded for now (need to move it to UI element)
          communication_preference: p0.email ? 1 : 0,
          name: p0.name,
          event: eventId2,
          has_registered: true,
          citizen_of_country: p0.citizen_of_country
            ? p0.citizen_of_country
            : "",
          has_oci: p0.has_oci || false,

          //
          ...x,

          //
          ...p2data,
        };
      });

      fetchSrcmApi({
        api: `/api/v3/events/${eventId2}/registrations/`,
        methodParams,
        client: "eventsClient",
        method: "POST",
      })
        .then((data2) => {
          if (!!data2 && data2.length > 0) {
            const pnr1 = data2[0].pnr;

            if (
              addTransportData.current?.sameStay &&
              addTransportData.current?.transport_restrictions &&
              addTransportData.current?.transport_restrictions.length > 0 &&
              computeRule(
                addTransportData.current?.transport_restrictions,
                data2[0]
              )
            ) {
              data2.map((ppl) =>
                updateTransportDetails(
                  pnr1,
                  ppl,
                  addTransportData.current?.ashram_id_for_stay,
                  addTransportData.current?.stays,
                  registerNewStays
                )
              );
            }
            data2.forEach((r) => {
              dispatch(receiveSrcmGResultDoc(cacheKey, r.id, r, "pnrs"));
            });
            dispatch(resetParticipantsPanel());
            dispatch(
              showAlert({
                message: `${cb?.(methodParams) || "Registered successfuly"}`,
                title: `PNR: ${pnr1}`,
              })
            );
            if (computePnrPageUrl) {
              navigate(computePnrPageUrl({ pnr: pnr1, eventId: eventId2 }));
            }
          } else {
            dispatch(resetSubmitParticipants());
            dispatch(
              showAlert({
                title: "Error",
                message: "Something went wrong. Please try again later.",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(resetSubmitParticipants());
          dispatch(hideParticipantsPanel());
          if (e.toString().includes("exceeds limit")) {
            dispatch(
              showAlert({
                title: "Error",
                message:
                  "Maximum registration reached for selected stay preference.",
              })
            );
          } else {
            dispatch(
              showAlert({
                title: "Error",
                message: handleError(e),
              })
            );
          }
        });
    },
    [dispatch, cacheKey, fetchSrcmApi, addTransportData, registerNewStays]
  );

  const eventUpdateReg1 = useCallback(
    (registrationId, patchBody, eventId2) => {
      return fetchSrcmApi({
        api: `/api/v3/events/${eventId2}/registrations/${registrationId}/`,
        methodParams: {
          ...patchBody,
          log: "Updating",
        },
        client: "eventsClient",
        method: "PATCH",
      })
        .then((data2) => {
          if (!!data2 && !!data2.status) {
            dispatch(receiveSrcmGResultDoc(cacheKey, data2.id, data2, "pnrs"));
          } else {
            setError("Error in Updating Registrations");
          }
        })
        .catch(() => {
          setError("Error in Updating");
        });
    },
    [fetchSrcmApi, setError, cacheKey, dispatch]
  );

  const eventUpdateReg = useCallback(
    (person, onS, onF) => {
      eventUpdateReg1(person.id, person, person.event_name)
        .then(onS)
        .catch(onF);
    },
    [eventUpdateReg1]
  );

  const eventCancelRegs = useCallback(
    (listIds, eid) => {
      listIds.forEach((personId) => {
        eventUpdateReg1(
          personId,
          {
            status: "cancelled",
            cancelled: new Date().valueOf() % 2147483647,
          },
          eid
        );
      });
    },
    [eventUpdateReg1]
  );

  return {
    recs: registrants,
    stays,
    error,
    sameStay,
    isFetching: loading,
    doRefresh,
    cancelStays: cancelRegs,
    registerNewStays,
    updateStay: updateReg,
    eventRegisterNew,
    eventUpdateReg,
    eventCancelRegs,
    updateSameStay,
  };
};

useMySrcmGlobalPnr.propTypes = {
  pnr: PropTypes.string.isRequired,
};

export default useMySrcmGlobalPnr;
