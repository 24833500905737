/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Spinner } from "office-ui-fabric-react";
import { computeRule } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import React from "react";
import { compareDateWithCurrentDate } from "sites-common/utils/FormatDate";
import HtmlDiv from "sites-common/components/HtmlDiv";
import {
  selectorParticipantsPanel,
  selectorSrcmToken,
} from "../../../state/selectors";

import useEventInfo from "../useEventInfo";

import useKanhaStayConf from "./useKanhaStayConf";
import {
  InternalButton,
  Notice,
} from "../../ParticipantsPanelGlobal/StyledButtons";
import AccoForm2 from "./AccoForm2";

export const AshramStaysMain = ({
  eventOptions,
  pnr,
  people,
  meditationCenterId,
  stays,
  registerNew,
  updateReg,
  cancelRegs,
  isFetching,
  checkDate,
  token,
  isEditable,
  updateSameStay,
}) => {
  const { styles } = useSelector(selectorParticipantsPanel);
  const displaySpecialAcco =
    stays.filter((item) => item.status !== "cancelled").length > 0;
  const { isLoaded } = useKanhaStayConf();

  if (!displaySpecialAcco && !checkDate) {
    return null;
  }

  return (
    <div sx={{ my: 3, border: "solid 1px", borderColor: styles.colors.well }}>
      <div sx={{ bg: styles.colors.well, px: 2, py: 2, mb: 2 }}>
        <Text sx={{ fontWeight: "600" }}>Arrivals & Departures</Text>
      </div>
      {eventOptions?.transport_note && (
        <div sx={{ p: 2, mb: 2 }}>
          <HtmlDiv htmlString={eventOptions.transport_note} />
        </div>
      )}
      <div sx={{ p: 1 }}>
        {isLoaded ? (
          <div>
            {people && people.length > 0 && (
              <AccoForm2
                eventOptions={eventOptions}
                people={people}
                pnr={pnr}
                meditationCenterId={meditationCenterId}
                stays={stays}
                registerNew={registerNew}
                updateReg={updateReg}
                cancelRegs={cancelRegs}
                isFetching={isFetching}
                checkDate={checkDate}
                token={token}
                isEditable={isEditable}
                updateSameStay={updateSameStay}
              />
            )}
            {eventOptions.ej_special_accommodation &&
              eventOptions.special_acc_people &&
              eventOptions.special_acc_people.length > 0 &&
              people && (
                <Notice mode="multiline">
                  <Text sx={{ fontSize: "1.2em" }}>
                    {" "}
                    Special Accommodation / Comfort Dorm Bookings{" "}
                  </Text>
                  <br />
                  <InternalButton
                    to={`/special-accommodation?pnr=${pnr}&force=true`}
                    sx={{}}
                  >
                    Book Comfort Dorm
                  </InternalButton>
                </Notice>
              )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

AshramStaysMain.defaultProps = {
  token: "",
  isEditable: true,
};

AshramStaysMain.propTypes = {
  eventOptions: PropTypes.shape({
    ej_special_accommodation: PropTypes.string,
    special_acc_people: PropTypes.arrayOf(PropTypes.shape({})),
    transport_note: PropTypes.string,
  }).isRequired,
  pnr: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meditationCenterId: PropTypes.number.isRequired,
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  cancelRegs: PropTypes.func.isRequired,
  registerNew: PropTypes.func.isRequired,
  updateReg: PropTypes.func.isRequired,
  checkDate: PropTypes.bool.isRequired,
  token: PropTypes.string,
  isEditable: PropTypes.bool,
  updateSameStay: PropTypes.func.isRequired,
};

const AshramStays = ({
  pnr,
  eventRecs,
  stays,
  registerNew,
  updateReg,
  cancelRegs,
  isFetching,
  updateSameStay,
}) => {
  const people = eventRecs?.filter((r) => r.status !== "cancelled");
  const events = people?.map((r) => r.event_name);

  const firstEventId = events?.length > 0 ? events[0] : null;

  const { customConfig, data } = useEventInfo(firstEventId);
  const checkDate =
    data?.reg_starts && data?.reg_ends
      ? !compareDateWithCurrentDate(data.reg_starts) &&
        compareDateWithCurrentDate(data.reg_ends)
      : false;

  const { token } = useSelector(selectorSrcmToken);

  const {
    transport_restrictions,
    ashram_id_for_stay,
    ej_checkout_ends,
    ej_checkout_starts,
    ej_checkin_ends,
    ej_checkin_starts,
    ej_ask_transportation,
    ej_special_accommodation,
    special_accommodation_restrictions,
    transport_note,
  } = customConfig;
  const special_acc_people = people?.filter((p) =>
    special_accommodation_restrictions &&
    special_accommodation_restrictions?.length > 0
      ? computeRule(special_accommodation_restrictions, p)
      : true
  );
  const eventOptions = {
    ej_checkout_ends,
    ej_checkout_starts,
    ej_checkin_ends,
    ej_checkin_starts,
    ej_ask_transportation,
    ej_special_accommodation,
    special_acc_people,
    transport_note,
  };
  const stayingPeople = people?.filter((p) =>
    transport_restrictions && transport_restrictions.length > 0
      ? computeRule(transport_restrictions, p)
      : true
  );

  if (ashram_id_for_stay) {
    return (
      <React.Fragment>
        <AshramStaysMain
          eventOptions={eventOptions}
          pnr={pnr}
          people={stayingPeople}
          meditationCenterId={ashram_id_for_stay}
          stays={stays}
          registerNew={registerNew}
          updateReg={updateReg}
          cancelRegs={cancelRegs}
          isFetching={isFetching}
          checkDate={checkDate}
          token={token}
          updateSameStay={updateSameStay}
        />
      </React.Fragment>
    );
  }

  return null;
};

AshramStays.propTypes = {
  pnr: PropTypes.string.isRequired,
  eventRecs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  cancelRegs: PropTypes.func.isRequired,
  registerNew: PropTypes.func.isRequired,
  updateReg: PropTypes.func.isRequired,
  updateSameStay: PropTypes.func.isRequired,
};

export default AshramStays;
