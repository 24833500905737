/** @jsx jsx */
import { DefaultButton } from "office-ui-fabric-react";
import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

import { genNewUuid } from "@heartfulnessinstitute/react-hfn-forms/dist/utils";
import {
  showParticipantsPanel,
  NEWPNR,
} from "../../state/actions/participantsPanel";
import useNewRegistrationSubmit from "./useNewRegistrationSubmit";
import { TransparentButton } from "../ParticipantsPanelGlobal/StyledButtons";
import useEventInfo from "../PnrSummary/useEventInfo";

const NewRegistration = ({
  eventId,
  registerBtnStyles,
  registerBtnText,
  computePnrPageUrl,
  pnr,
  registerNew,
  noUid = "",
  existing,
}) => {
  const dispatch = useDispatch();
  const { data } = useEventInfo(eventId);
  const customConfig =
    data && data.event_json && data.event_json.custom_config_json
      ? JSON.parse(data.event_json.custom_config_json)
      : {};

  const {
    constraints,
    p2fieldmap,
    colorScheme,
    reg_status,
    isParentEvent,
    only_couple_allowed,
    max_num_participants: maxNumParticipants,
    mandate_panel_fields: mandatePanelFields,
    max_male_participant: maxMaleParticipant,
    max_female_participant: maxFemaleParticipant,
    restrict_child_reg: restrictChildReg,
    check_id_proof: checkIdProof,
    check_id_proof_condition: checkIdProofCondition,
    age_limitation: ageLimitation,
    allowed_reg_roles: allowedRegRoles,
  } = customConfig;
  const [uniqref] = useState(genNewUuid());

  useNewRegistrationSubmit({
    eventId,
    uniqref: noUid || uniqref,
    computePnrPageUrl,
    registerNew,
    reg_status,
    reg_needs_approval: data?.reg_needs_approval || false,
    parentEvent: data?.parent_event,
    isParentEvent,
    only_couple_allowed,
  });

  const openIt = useCallback(() => {
    dispatch(
      showParticipantsPanel({
        uniqref: noUid || uniqref,
        colorScheme,
        constraints,
        p2fieldmap,
        pnr,
        maxNumParticipants,
        mandatePanelFields,
        maxMaleParticipant,
        maxFemaleParticipant,
        restrictChildReg,
        checkIdProof,
        checkIdProofCondition,
        ageLimitation,
        existing,
        allowedRegRoles,
      })
    );
  }, [
    dispatch,
    uniqref,
    pnr,
    colorScheme,
    constraints,
    p2fieldmap,
    noUid,
    maxNumParticipants,
    mandatePanelFields,
    maxMaleParticipant,
    maxFemaleParticipant,
    restrictChildReg,
    checkIdProof,
    checkIdProofCondition,
    ageLimitation,
    existing,
    allowedRegRoles,
  ]);

  return (
    <div>
      {registerBtnStyles ? (
        <DefaultButton
          sx={{ ...registerBtnStyles }}
          text={registerBtnText}
          onClick={openIt}
        />
      ) : (
        <TransparentButton text={registerBtnText} onClick={openIt} />
      )}
    </div>
  );
};

NewRegistration.propTypes = {
  eventId: PropTypes.string.isRequired,
  registerBtnStyles: PropTypes.shape({}),
  registerBtnText: PropTypes.string,
  computePnrPageUrl: PropTypes.func,
  pnr: PropTypes.string,
  registerNew: PropTypes.func,
  noUid: PropTypes.string,
  existing: PropTypes.instanceOf(PropTypes.array),
};
NewRegistration.defaultProps = {
  registerBtnStyles: undefined,
  registerBtnText: "Register",
  computePnrPageUrl: null,
  pnr: NEWPNR,
  registerNew: undefined,
  noUid: "",
  existing: [],
};

export default React.memo(NewRegistration);
