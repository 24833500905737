/** @jsx jsx */
import { jsx, Container, Text, Flex } from "theme-ui";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { validateEmail } from "sites-common/utils/validations";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { DefaultButton, Stack } from "office-ui-fabric-react";
import { computeRule } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import useEventInfo from "gatsby-plugin-hfn-profile/components/PnrSummary/useEventInfo";
import { AshramStaysMain } from "gatsby-plugin-hfn-profile/components/PnrSummary/AshramStays/index";
import useMySrcmEvent from "gatsby-plugin-hfn-profile/components/SrcmModels/useMySrcmEvent";
import { compareDateWithCurrentDate } from "sites-common/utils/FormatDate";
import { useSelector } from "react-redux";
import {
  selectorSrcmProfile,
  selectorParticipantsPanel,
} from "gatsby-plugin-hfn-profile/state/selectors";
import NewRegistration from "gatsby-plugin-hfn-profile/components/EventRegistrations/NewRegistration";
import {
  SecondaryButton,
  TransparentButton,
} from "gatsby-plugin-hfn-profile/components/ParticipantsPanelGlobal/StyledButtons";
import useFetchOmsApi from "gatsby-plugin-hfn-profile/components/Hooks/useFetchOmsApi";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { get, flattenDeep } from "sites-common/utils/lodash";
import useMySrcmGlobalPnr from "../../utils/useMySrcmGlobalPnr";
import authRestriction from "../../utils/authRestriction";
import PnrSummaryComponent from "../../components/PnrSummaryComponent";
import PnrHeader from "../../components/PnrHeader";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { domainKanha } = getMandatoryEnv(["domainKanha"]);
const orderConfirmed = "ORDER_CONFIRMED";
const itemCancelled = "ITEM_CANCELLED";

const computePnrPageUrl = ({ pnr }) => `/pnr-search/pnr?pnr=${pnr}`;

const getFilteredRegistrations = (registrations) =>
  registrations.filter((r) => r.status !== "cancelled");

const compareDateCondition = (eventDetails) =>
  eventDetails?.reg_starts && eventDetails?.reg_ends
    ? !compareDateWithCurrentDate(eventDetails.reg_starts) &&
      compareDateWithCurrentDate(eventDetails.reg_ends)
    : false;

const PnrSummaryDetails = ({ location }) => {
  const pnr = useMemo(() => {
    const pd = new URLSearchParams(location.search).get("pnr");
    return pd;
  }, [location]);

  const srcmProfile = useSelector(selectorSrcmProfile);
  const { styles } = useSelector(selectorParticipantsPanel);
  let rolesList;
  if (srcmProfile && srcmProfile.roles) {
    rolesList = [...srcmProfile.roles];
  }
  const {
    recs: registrations,
    stays,
    isFetching,
    cancelStays,
    registerNewStays,
    updateStay,
    eventUpdateReg,
    eventCancelRegs,
    eventRegisterNew,
    updateSameStay,
  } = useMySrcmGlobalPnr({ pnr });
  const { showAlert } = useAlert();

  const { fetchSrcmApi } = useFetchSrcmApi();
  const { fetchOmsApi } = useFetchOmsApi();

  const [
    showCancelSelections,
    { setTrue: setShowCancelSelections, setFalse: hideCancelSelections },
  ] = useMyBoolean(false);

  const [listSelected, setListSelected] = useState({});
  const [listCancelling, setListCancelling] = useState({});
  const [confirmedOrdersData, setConfirmedOrdersData] = useState([]);

  useEffect(() => {
    fetchOmsApi({
      api: `/order-by-pnr/${pnr}`,
    }).then((res) => {
      if (res && res.order) {
        const orders = res.order.filter(
          (item) => item.status === orderConfirmed
        );
        setConfirmedOrdersData(orders);
      }
    });
  }, [pnr, fetchOmsApi]);

  const { customConfig } = useEventInfo(registrations[0]?.event_name) || {};
  const goBack = () => navigate("/pnr-search");
  const goToPaymentReport = () => navigate(`/payment-pnr-search?pnr=${pnr}`);

  const people = getFilteredRegistrations(registrations);
  const { data: eventDetails } = useMySrcmEvent({
    name: registrations[0]?.event_name,
  });

  const uncancelledRecIds = useMemo(
    () => JSON.stringify(people.map((r) => r.id)),
    [people]
  );
  useEffect(() => {
    const y = JSON.parse(uncancelledRecIds);
    setListCancelling((x1) =>
      Object.fromEntries(Object.entries(x1).filter((x) => y.includes(x)))
    );
  }, [uncancelledRecIds]);
  const numCancel = useMemo(
    () => Object.values(listSelected).filter((x) => x).length,
    [listSelected]
  );

  const checkDate = compareDateCondition(eventDetails);

  const isEditable = useMemo(
    () => !!rolesList.includes("info_center"),
    [rolesList]
  );

  const dupFreeRecs = useMemo(() => {
    const getUniqRef = (r) =>
      ["B99999999", "B99999998"].includes(r.ref)
        ? r.name.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g, "")
        : r.ref;
    const uncancelledRecRefs = people?.map(getUniqRef);
    const cancelledRefs = registrations?.filter(
      (r) => r.status === "cancelled"
    );
    return registrations?.filter(
      (r) =>
        r.status !== "cancelled" ||
        (r.status === "cancelled" &&
          !uncancelledRecRefs.includes(getUniqRef(r)) &&
          !cancelledRefs.some(
            (cr) => getUniqRef(cr) === getUniqRef(r) && cr.id > r.id
          ))
    );
  }, [people, registrations]);

  const recs = useMemo(
    () =>
      dupFreeRecs.filter(
        (rec) => rec.event_name === registrations[0].event_name
      ),
    [dupFreeRecs, registrations]
  );

  const eventId = useMemo(
    () => (eventDetails ? eventDetails.name : ""),
    [eventDetails]
  );

  const doNotCancel = useCallback(() => {
    hideCancelSelections();
    setListSelected({});
  }, [hideCancelSelections, setListSelected]);

  const uncancelled = useMemo(() => people.length, [people]);

  const doCancel = useCallback(() => {
    const l = Object.entries(listSelected)
      .filter(([, v]) => v)
      .map(([k]) => k);
    // .map(([k]) => parseInt(k, 10));
    const xyz = confirmedOrdersData.map((item) => {
      return item.items.map((item2) => {
        if (item2.status !== itemCancelled) {
          return item2?.details?.eid;
        }
        return null;
      });
    });
    const confirmedKeys = flattenDeep(xyz).filter((item) => item);
    const migrate = [];
    const dummyHolder = l.map((i) => {
      if (confirmedKeys.includes(i)) {
        migrate.push(true);
      }
      return false;
    });
    if (migrate && migrate.includes(true)) {
      showAlert({
        title: "Alert",
        message: `Please cancel your existing accommodation before proceeding. Do you wish to cancel your accommodation?`,
        confirm_text: "Yes",
        cancel_text: "No",
        confirm_callback: () =>
          navigate(
            `${domainKanha}/special-accommodation?pnr=${pnr}&force=true&tab=confirmed-bookings`
          ),
        discard_callback: () => doNotCancel(),
      });
    } else {
      const lmap = l.map((k) => parseInt(k, 10));
      showAlert({
        title: "Alert",
        message: `Are you sure?`,
        confirm_text: "Yes",
        cancel_text: "No",
        confirm_callback: () =>
          eventCancelRegs(lmap, recs[0].event_name, dummyHolder),
      });
      doNotCancel();
    }
  }, [
    eventCancelRegs,
    listSelected,
    doNotCancel,
    recs,
    confirmedOrdersData,
    pnr,
    showAlert,
  ]);

  const {
    stay_restrictions,
    transport_restrictions,
    ashram_id_for_stay,
    ej_checkout_ends,
    ej_checkout_starts,
    ej_checkin_ends,
    ej_checkin_starts,
    ej_ask_transportation,
  } = customConfig;

  const updateData = useCallback(
    (e) => {
      const methodParamsY = e;
      if (!e?.mobile) {
        delete methodParamsY.mobile;
      }
      if (!e?.email) {
        delete methodParamsY.email;
      }
      eventUpdateReg(methodParamsY);
    },
    [eventUpdateReg]
  );

  const updateRegistrations = useCallback(
    async (e, onSuccess) => {
      const defaultRef = "B99999999";
      const childRef = "B99999998";
      function apiCall(methodParamsX, oldDataX) {
        if (
          methodParamsX?.part_name === oldDataX.name &&
          (methodParamsX?.email === oldDataX.email ||
            methodParamsX?.mobile === oldDataX.mobile)
        ) {
          updateData(e);
        } else {
          fetchSrcmApi({
            api: `/api/v3/events/${e.event_name}/is_registered/`,
            methodParams: methodParamsX,
            client: "eventsClient",
            method: "GET",
          })
            .then((data) => {
              if (data && data.registered === true) {
                showAlert({
                  title: "Error",
                  message: `Already Registered in another PNR`,
                  confirm_text: "Okay",
                  is_blocking: true,
                });
              }
              if (data && data.registered === false) {
                updateData(e);
              }
            })
            .catch(() => {
              showAlert({
                title: "Error",
                message: `Network Error or Server Error`,
                confirm_text: "Okay",
                is_blocking: true,
              });
            });
        }
      }
      // if no changes made
      const oldFields = registrations.find((item) => item.id === e.id);
      if (JSON.stringify(oldFields) === JSON.stringify(e)) {
        onSuccess();
        return null;
      }

      const methodParams = {};

      if (e.ref === defaultRef || e.ref === childRef) {
        if (e.email) {
          methodParams.email = e.email;
        }
        if (e.mobile) {
          methodParams.mobile = e.mobile;
        }
        methodParams.part_name = e.name;
      } else {
        methodParams.partner_id = e.id;
      }
      const oldData = registrations.filter((c) => c.id === e.id)[0];
      if (
        oldData.stay_preference !== e.stay_preference &&
        stay_restrictions &&
        !computeRule(stay_restrictions, e)
      ) {
        showAlert({
          title: "Alert",
          message: `Changing the stay preference will cancel your stay. Are you sure you want to cancel the stay?`,
          confirm_text: "Yes",
          cancel_text: "No",
          confirm_callback: () => apiCall(methodParams, oldData),
        });
      } else {
        apiCall(methodParams, oldData);
      }
      onSuccess();
      return null;
    },
    [fetchSrcmApi, showAlert, registrations, updateData, stay_restrictions]
  );

  const validation = useCallback((r) => {
    if (!!r && (!r.name || !r.stay_preference)) {
      return "Please fill all required fields";
    }
    if (!!r && r.name < 3) {
      return "name should be minimum 3 characters";
    }
    if (!!r && !r.mobile && !r.email) {
      return "Enter either Mobile or Email";
    }
    if (!!r && !!r.mobile) {
      if (!r.mobile.startsWith("+")) {
        return "Mobile number needs to start with +(country code)";
      }
    }

    if (!!r && !!r.email) {
      if (!validateEmail(r.email)) {
        return "Enter a valid email";
      }
    }

    return false;
  }, []);

  const eventOptions = {
    ej_checkout_ends,
    ej_checkout_starts,
    ej_checkin_ends,
    ej_checkin_starts,
    ej_ask_transportation,
  };

  const stayingPeople = useMemo(
    () =>
      people.filter((p) =>
        transport_restrictions ? computeRule(transport_restrictions, p) : true
      ),
    [people, transport_restrictions]
  );

  return (
    <div>
      <PnrHeader />
      <Container>
        <div
          sx={{
            my: 3,
            px: 2,
            pb: 2,
            maxWidth: ["100%", "90%", "80%"],
            mx: "auto",
          }}
        >
          <Flex>
            <DefaultButton sx={{ width: "150px" }} onClick={() => goBack()}>
              Go Back
            </DefaultButton>
            <div sx={{ mb: 2, textAlign: "center", flex: "100%" }}>
              <Text sx={{ fontSize: "1em", fontWeight: "300" }}> PNR: </Text>
              <Text sx={{ fontSize: "1.2em" }}> {pnr} </Text>
            </div>
            <DefaultButton
              sx={{ width: "200px" }}
              onClick={() => goToPaymentReport()}
            >
              Payment Report
            </DefaultButton>
          </Flex>
          <div sx={{ border: "solid 1px", borderColor: "rgb(214, 173, 96)" }}>
            <div sx={{ bg: "rgb(214, 173, 96)", px: 2, py: 2, mb: 2 }}>
              <Text sx={{ fontSize: "0.8em" }}>Event</Text>
              <br />
              <Text sx={{ fontWeight: "600" }}>
                {registrations && registrations[0]?.event_title}
              </Text>
            </div>
            {registrations.length
              ? registrations.map((item, idx) => {
                  const newFields = item;
                  if (item?.age_group) {
                    newFields.age_group = parseInt(item.age_group, 10);
                  }
                  return (
                    <PnrSummaryComponent
                      checkDate={checkDate}
                      person={newFields}
                      stays={stays.find(
                        ({ name, email, mobile }) =>
                          name + email + mobile ===
                          item.name + item.email + item.mobile
                      )}
                      idx={idx + 1}
                      inProgress={
                        get(listCancelling, `${item.id}`, false) || isFetching
                      }
                      listSelected={listSelected}
                      onSelectToggle={
                        showCancelSelections && !!eventCancelRegs
                          ? setListSelected
                          : null
                      }
                      onUpdate={updateRegistrations}
                      validation={validation}
                      rolesList={rolesList}
                    />
                  );
                })
              : ""}
            {checkDate && rolesList.includes("info_center") ? (
              <div sx={{ px: 2, py: 2, mb: 2 }}>
                {!showCancelSelections ? (
                  <Stack sx={{ py: 2 }} horizontal tokens={{ childrenGap: 10 }}>
                    {customConfig && !customConfig.hidenewregistration && (
                      <NewRegistration
                        eventId={eventId}
                        registerBtnText="Add More Participants"
                        computePnrPageUrl={computePnrPageUrl}
                        pnr={pnr}
                        registerNew={eventRegisterNew}
                      />
                    )}
                    {uncancelled > 0 && (
                      <TransparentButton
                        text="Cancel Some Registrations"
                        onClick={setShowCancelSelections}
                      />
                    )}
                  </Stack>
                ) : (
                  <Stack
                    sx={{ py: 2 }}
                    horizontal
                    horizontalAlign="space-between"
                  >
                    {numCancel ? (
                      <SecondaryButton
                        text={`Cancel ${numCancel} Registration${
                          numCancel > 1 ? "s" : ""
                        }`}
                        onClick={doCancel}
                      />
                    ) : (
                      // </ConfirmButton>
                      <Text sx={{ color: styles.colors.error }}>
                        Select Participants to Cancel
                      </Text>
                    )}
                    <TransparentButton
                      iconProps={{ iconName: "Cancel" }}
                      text="Do Not Cancel"
                      onClick={doNotCancel}
                    />
                  </Stack>
                )}
              </div>
            ) : (
              ""
            )}
            {stayingPeople?.length ? (
              <div
                sx={{
                  m: 2,
                }}
              >
                <AshramStaysMain
                  checkDate={checkDate}
                  eventOptions={eventOptions}
                  pnr={registrations[0]?.pnr || ""}
                  people={stayingPeople}
                  meditationCenterId={ashram_id_for_stay}
                  isFetching={isFetching}
                  stays={stays}
                  registerNew={registerNewStays}
                  updateReg={updateStay}
                  cancelRegs={cancelStays}
                  isEditable={isEditable}
                  updateSameStay={updateSameStay}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

PnrSummaryDetails.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

PnrSummaryDetails.defaultProps = {
  location: { search: "" },
};

export default authRestriction(PnrSummaryDetails);
