import { useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFetchSrcmApi } from "../../fetch-srcm-api";
import requestJson from "../../state/actions/common-utils";
import {
  invalidateJson,
  receiveJson,
} from "../../state/actions/fetchSrcmApiIfNeeded";
import { selectorJson } from "../../state/selectors";

const useEventInfo = (eventId) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const dispatch = useDispatch();
  const url = useMemo(() => `/api/v3/events/${eventId}/`, [eventId]);

  const { isLoaded, isFetching, data, errorMessage } = useSelector((state) =>
    selectorJson(state, url)
  );

  const fetchEvents = useCallback(() => {
    dispatch(requestJson(url));
    fetchSrcmApi({
      api: url,
      client: "eventsClient",
    })
      .then((result) => {
        dispatch(receiveJson(url, result));
      })
      .catch(() => dispatch(invalidateJson(url, "Url Not Found")));
  }, [fetchSrcmApi, dispatch, url]);

  useEffect(() => {
    if (isFetching || isLoaded) {
      return false;
    }
    if (errorMessage) {
      return false;
    }
    if (eventId && !data) {
      fetchEvents();
    }
    return false;
  }, [fetchEvents, eventId, data, isFetching, isLoaded, errorMessage]);

  let customConfig = {};
  if (data && data.event_json && data.event_json.custom_config_json) {
    customConfig = JSON.parse(data.event_json.custom_config_json);
  }

  return {
    data,
    isLoaded,
    isFetching,
    eventJson: data?.event_json,
    customConfig,
  };
};

export default useEventInfo;
