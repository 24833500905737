import PropTypes from "prop-types";
import { useSrcmApi } from "../../fetch-srcm-api";

function useMySrcmModel({ api, methodParams, client }) {
  const apiParams = { api, methodParams, client };

  const cacheKey = api ? `${api}${JSON.stringify(methodParams)}` : "";
  const { isFetching, data, errorMessage, isLoaded, refresh, updateParams } =
    useSrcmApi(cacheKey, apiParams);
  return {
    isFetching,
    data,
    errorMessage,
    isLoaded,
    refresh,
    updateParams,
    cacheKey,
  };
}

useMySrcmModel.propTypes = {
  api: PropTypes.string.isRequired,
  methodParams: PropTypes.shape({}).isRequired,
  client: PropTypes.string.isRequired,
};

export default useMySrcmModel;
