import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchJsonIfNeeded as A } from "../../../state/actions";
import { selectorJson as S } from "../../../state/selectors";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { kanhaStaticConfig } = getMandatoryEnv(["kanhaStaticConfig"]);

function useKanhaStayConf() {
  const key = "ashram-kanha-stay-conf";
  const dispatch = useDispatch();
  const { isLoaded, isFetching, data } = useSelector((state) => S(state, key));

  useEffect(() => {
    const url = `${kanhaStaticConfig}/config.json`;
    if (!isFetching && !isLoaded && `${url}`) {
      dispatch(A(key, `${url}`, {}));
    }
  }, [isFetching, isLoaded, dispatch]);

  return {
    isLoaded,
    conf: isLoaded ? data : {},
  };
}

export default useKanhaStayConf;
