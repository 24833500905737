import { useCallback, useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import handleError from "sites-common/utils/handleError";
import { computeRule } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import useEventInfo from "./useEventInfo";
import copyKeys from "../../lib/copyKeys";
import {
  hideParticipantsPanel,
  resetParticipantsPanel,
  resetSubmitParticipants,
} from "../../state/actions/participantsPanel";
import { useFetchSrcmApi } from "../../fetch-srcm-api";
import {
  receiveSrcmResultDoc,
  showAlert,
  receiveSrcmStayResultDoc,
} from "../../state/actions";
import useMySrcmModel from "../SrcmModels/useMySrcmModel";
import { recieveSrcmSameStay } from "../../state/actions/receiveSrcmResultDoc";

export const transportInfoKeys = [
  "arrival_date",
  "arrival_mode",
  "arrival_mode2",
  "arrival_time",
  "departure_date",
  "departure_mode",
  "departure_mode2",
  "departure_time",
];

const regJsonFields = ["gov_id_type", "gov_id", "alternate_phone"];

export function updateTransportDetails(
  pnr,
  person,
  medId,
  stay,
  registerNewStays
) {
  const newData = copyKeys(stay || {}, transportInfoKeys);
  const abc = {
    ...copyKeys(
      person,
      [
        "event_name",
        "name",
        "ref",
        "mobile",
        "email",
        "partner_id",
        "age_group",
        "gender",
        "city_id",
        "citizen_of_country",
        "has_oci",
        "stay_preference",
        "id",
      ],
      { event_name: "event", id: "reg_id" }
    ),

    // TEMPORARY FIX -- need to remove this line.  city_id is always expected to be set in event_rec
    city_id:
      person.city_id && typeof person.city_id === "number" ? person.city_id : 0,
    travel_plan: "travel_confirmed",
    citizen_of_country: person.citizen_of_country
      ? person.citizen_of_country
      : "",
    ...newData,
  };

  registerNewStays(pnr, medId, [abc], {});
}

const useMySrcmPnr = ({ pnr, params }) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [error3, setError] = useState(null);

  const addTransportData = useRef({});

  const {
    data,
    isLoaded,
    errorMessage,
    refresh: doRefresh,
    cacheKey,
  } = useMySrcmModel({
    api: `/api/v3/me/pnrs/`,
    client: "eventsClient",
    methodParams: {
      ...(pnr ? { pnr } : {}),
      ...params,
    },
  });
  const recsData = useMemo(
    () =>
      data?.results[0]?.stays
        ?.filter((r) => r.pnr === pnr && r.status !== "cancelled")
        ?.map((r) => ({ ...r, ...r.stay_json })) || [],
    [data, pnr]
  );
  const stays = useMemo(
    () => (isLoaded ? recsData || [] : []),
    [isLoaded, recsData]
  );
  const registrants = isLoaded ? data?.results[0]?.registrations : [];

  const events = registrants?.map((r) => r.event_name);
  const eventId = events ? events[0] : "";
  const { customConfig } = useEventInfo(eventId);
  const { transport_restrictions, ashram_id_for_stay } = customConfig;

  const loading = !isLoaded;
  const error = errorMessage ? "Error in Fetching Stay Registrations" : error3;
  const sameStay = data?.results[0]?.same_stay;

  const dispatch = useDispatch();

  useEffect(() => {
    addTransportData.current = {
      stays: stays && stays.length > 0 ? stays[stays.length - 1] : {},
      transport_restrictions,
      sameStay,
      ashram_id_for_stay,
    };
  }, [stays, transport_restrictions, sameStay, ashram_id_for_stay]);

  const updateSameStay = useCallback(
    (ss) => {
      if (ss !== addTransportData.current?.sameStay) {
        return fetchSrcmApi({
          api: `/api/v3/pnrs/${pnr}/`,
          methodParams: {
            same_stay: ss,
          },
          client: "eventsClient",
          method: "PATCH",
        })
          .then(() => {
            dispatch(recieveSrcmSameStay(cacheKey, ss));
          })
          .catch(() => {});
      }
      return null;
    },
    [fetchSrcmApi, pnr, cacheKey, dispatch]
  );

  const registerNewStays = useCallback(
    (pnr2, meditationCenterId, visitors, p2data) => {
      if (!(visitors && visitors.length > 0)) {
        return;
      }

      const methodParams = visitors.map((p0) => {
        return {
          pnr: pnr2,
          communication_preference: p0.email ? 1 : 0,
          meditation_center: meditationCenterId,
          ...p0,
          stay_json: {
            ...p2data,
          },
        };
      });

      fetchSrcmApi({
        api: `/api/v3/ashram-stays/`,
        methodParams,
        client: "eventsClient",
        method: "POST",
      })
        .then((data2) => {
          if (!!data2 && data2.length > 0) {
            data2.forEach((r) => {
              dispatch(receiveSrcmStayResultDoc(cacheKey, r.id, r));
            });
          } else {
            dispatch(
              showAlert({
                title: "Error",
                message: "Something went wrong. Please try again later.",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(
            showAlert({
              title: "Error",
              message: handleError(e),
            })
          );
        });
    },
    [dispatch, fetchSrcmApi, cacheKey]
  );

  const updateReg1 = useCallback(
    (registrationId, patchBody) => {
      return fetchSrcmApi({
        api: `/api/v3/ashram-stays/${registrationId}/`,
        methodParams: {
          ...patchBody,
          log: "Updating",
          stay_json: {
            ...patchBody.stay_json,
          },
          meditation_center:
            typeof patchBody.meditation_center === "object"
              ? patchBody.meditation_center.id
              : patchBody.meditation_center,
        },
        client: "eventsClient",
        method: "PATCH",
      })
        .then((data2) => {
          if (!!data2 && !!data2.status) {
            dispatch(receiveSrcmStayResultDoc(cacheKey, data2.id, data2));
          } else {
            setError("Error in Updating Registrations");
          }
        })
        .catch((e) => {
          setError(handleError(e));
        });
    },
    [fetchSrcmApi, setError, cacheKey, dispatch]
  );

  const updateReg = useCallback(
    (person, onS, onF) => {
      updateReg1(person.id, person).then(onS).catch(onF);
    },
    [updateReg1]
  );

  const cancelRegs = useCallback(
    (listIds) => {
      listIds.forEach((personId) => {
        updateReg1(personId, {
          status: "cancelled",
          cancelled: new Date().valueOf() % 2147483647,
        });
      });
    },
    [updateReg1]
  );

  const eventRegisterNew = useCallback(
    (
      pnr2,
      eventId2,
      persons,
      p2data,
      computePnrPageUrl,
      cb,
      only_couple_allowed = false
    ) => {
      const defaultRef = "B99999999";
      const childRef = "B99999998";

      const methodParams = persons.map((p0) => {
        let x = {};

        if (p0.ref === defaultRef || p0.ref === childRef) {
          x = {
            gender: p0.gender,
            age_group: p0.age_group,
            city_id: p0.city_id,
            ref: p0.ref,
          };
          if (p0.email) {
            x.email = p0.email;
          }
          if (p0.mobile) {
            x.mobile = p0.mobile;
          }
        } else {
          if (p0?.extras) {
            Object.entries(p0.extras).map(([key, val]) => {
              if (val) {
                x[key] = val;
              }
              return null;
            });
          }
          x.partner_id = p0.id;
        }

        return {
          pnr: pnr2,
          // XXX - hardcoded for now (need to move it to UI element)
          communication_preference: p0.email ? 1 : 0,
          name: p0.name,
          event: eventId2,
          has_registered: true,
          citizen_of_country: p0.citizen_of_country
            ? p0.citizen_of_country
            : "",
          has_oci: p0.has_oci || false,
          reg_json: JSON.stringify(copyKeys(p0, regJsonFields)),
          //
          ...x,
          //
          ...p2data,
        };
      });
      fetchSrcmApi({
        api: `/api/v3/events/${eventId2}/registrations/${
          only_couple_allowed ? "?only_couple_allowed=true" : ""
        }`,
        methodParams,
        client: "eventsClient",
        method: "POST",
      })
        .then((data2) => {
          if (!!data2 && data2.length > 0) {
            const pnr1 = data2[0].pnr;
            if (
              addTransportData.current?.sameStay &&
              addTransportData.current?.transport_restrictions &&
              addTransportData.current?.transport_restrictions.length > 0 &&
              computeRule(
                addTransportData.current?.transport_restrictions,
                data2[0]
              )
            ) {
              data2.map((ppl) =>
                updateTransportDetails(
                  pnr1,
                  ppl,
                  addTransportData.current?.ashram_id_for_stay,
                  addTransportData.current?.stays,
                  registerNewStays
                )
              );
            }

            data2.forEach((r) => {
              dispatch(receiveSrcmResultDoc(cacheKey, r.id, r, "pnrs"));
            });
            dispatch(resetParticipantsPanel());
            dispatch(
              showAlert({
                message: `${cb?.(methodParams) || "Registered successfuly"}`,
                title: `PNR: ${pnr1}`,
              })
            );
            if (computePnrPageUrl) {
              navigate(computePnrPageUrl({ pnr: pnr1, eventId: eventId2 }));
            }
          } else {
            dispatch(resetSubmitParticipants());
            dispatch(
              showAlert({
                title: "Error",
                message: "Something went wrong. Please try again later.",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(resetSubmitParticipants());
          dispatch(hideParticipantsPanel());
          if (e.toString().includes("exceeds limit")) {
            dispatch(
              showAlert({
                title: "Error",
                message:
                  "Maximum registration reached for selected stay preference.",
              })
            );
          } else if (e.toString().includes("already registered")) {
            return null;
          } else {
            dispatch(
              showAlert({
                title: "Error",
                message: handleError(e),
              })
            );
          }
          return null;
        });
    },
    [dispatch, cacheKey, fetchSrcmApi, addTransportData, registerNewStays]
  );

  const eventUpdateReg1 = useCallback(
    (registrationId, patchBody, eventId2) => {
      return fetchSrcmApi({
        api: `/api/v3/events/${eventId2}/registrations/${registrationId}/`,
        methodParams: {
          ...patchBody,
          log: "Updating",
        },
        client: "eventsClient",
        method: "PATCH",
      })
        .then((data2) => {
          if (!!data2 && !!data2.status) {
            dispatch(receiveSrcmResultDoc(cacheKey, data2.id, data2, "pnrs"));
          } else {
            dispatch(
              showAlert({
                title: "Error",
                message: "Error in Updating Registrations",
              })
            );
          }
        })
        .catch((e) => {
          // const errorCheck = `${e}`.includes("Auth")
          //   ? "Token Expired. Please refresh the page."
          //   : "Something went wrong, please refresh the page.";
          dispatch(
            showAlert({
              title: "Error",
              message: handleError(e),
            })
          );
          // setError(e);
        });
    },
    [fetchSrcmApi, cacheKey, dispatch]
  );

  const eventUpdateReg = useCallback(
    (person, onS, onF) => {
      eventUpdateReg1(
        person.id,
        { ...person, has_oci: person.has_oci || false },
        person.event_name
      )
        .then(onS)
        .catch(onF);
    },
    [eventUpdateReg1]
  );

  const eventCancelRegs = useCallback(
    (listIds, eid) => {
      listIds.forEach((personId) => {
        eventUpdateReg1(
          personId,
          {
            status: "cancelled",
            cancelled: new Date().valueOf() % 2147483647,
          },
          eid
        );
      });
    },
    [eventUpdateReg1]
  );

  return {
    recs: registrants || [],
    stays,
    error,
    sameStay,
    isFetching: loading,
    doRefresh,
    cancelStays: cancelRegs,
    registerNewStays,
    updateStay: updateReg,
    eventRegisterNew,
    eventUpdateReg,
    eventCancelRegs,
    updateSameStay,
  };
};

useMySrcmPnr.defaultProps = {
  params: {},
};

useMySrcmPnr.propTypes = {
  pnr: PropTypes.string.isRequired,
  params: PropTypes.objectOf(PropTypes.any),
};

export default useMySrcmPnr;
