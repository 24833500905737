/** @jsx jsx */
import { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import handleError from "sites-common/utils/handleError";

import { useFetchSrcmApi } from "../../fetch-srcm-api";
import {
  errorParticipantValidation,
  inProgressParticipantValidation,
  validatedParticipant,
} from "../../state/actions/participantsPanel";
import { P_TO_VALIDATE, P_VALIDATED } from "../../state/panelsReducer";
import { selectorParticipantsPanel } from "../../state/selectors";

export const regLimitCb = (data, reg_status, reg_needs_approval) => {
  if (reg_status && reg_status.length > 0 && data && data.length > 0) {
    const filteredData = data.map((item) => item?.stay_preference);
    const filteredStatus = reg_status.filter((item) => {
      return (
        item?.status === "pending" &&
        filteredData.includes(item?.stay_preference)
      );
    });
    if (filteredStatus.length > 0) {
      return "Registration is in a pending state. Kindly update the Transport and Accommodation details to confirm registration within 3 days to avoid auto cancellation.";
    }
  }
  if (reg_needs_approval) {
    return "Registration is in pending status. Event organizers will review and confirm your registration.";
  }
  return null;
};

const useNewRegistrationSubmit = ({
  eventId,
  uniqref: myref,
  computePnrPageUrl,
  registerNew,
  reg_status,
  reg_needs_approval,
  parentEvent,
  isParentEvent,
  only_couple_allowed = false,
}) => {
  const { pnr, persons, keys, p2data, submit, uniqref } = useSelector(
    selectorParticipantsPanel
  );
  const { fetchSrcmApi } = useFetchSrcmApi();
  const dispatch = useDispatch();
  const defaultRef = "B99999999";
  const childRef = "B99999998";

  const pendingVerificationPersons = useMemo(
    () =>
      Object.entries(keys)
        .filter(([, status]) => status[0] === P_TO_VALIDATE)
        .map(([key]) => persons.find((r) => r.key === key)),
    [keys, persons]
  );

  const doSubmission =
    submit &&
    Object.values(keys).filter((status) => status[0] !== P_VALIDATED).length ===
      0;

  const doValidations = !submit && pendingVerificationPersons.length > 0;

  const refmatch = myref === uniqref;

  useEffect(() => {
    if (!refmatch) {
      return;
    }
    if (doSubmission) {
      // Form is Submitted. Proceed with Registration

      registerNew(
        pnr,
        p2data?.batch || eventId,
        persons,
        p2data,
        computePnrPageUrl,
        (a) => regLimitCb(a, reg_status, reg_needs_approval),
        only_couple_allowed
      );
    }
  }, [
    persons,
    p2data,
    eventId,
    doSubmission,
    dispatch,
    fetchSrcmApi,
    refmatch,
    pnr,
    computePnrPageUrl,
    registerNew,
    reg_status,
    reg_needs_approval,
    only_couple_allowed,
  ]);

  useEffect(() => {
    if (!refmatch) {
      return;
    }

    if (doValidations) {
      // Form is not yet submitted
      // Do validations for each of the records which are in to-be-validated state

      pendingVerificationPersons.forEach((p) => {
        const methodParams = {};

        if (p.ref === defaultRef || p.ref === childRef) {
          if (p.email) {
            methodParams.email = p.email;
          }
          if (p.mobile) {
            methodParams.mobile = p.mobile;
          }
          methodParams.part_name = p.name;
        } else {
          methodParams.partner_id = p.id;
        }

        dispatch(inProgressParticipantValidation(p.key));
        fetchSrcmApi({
          api: `/api/v3/events/${parentEvent || eventId}/is_registered/`,
          methodParams,
          client: "eventsClient",
          method: "GET",
        })
          .then((data) => {
            if (data && data.registered === true) {
              dispatch(
                errorParticipantValidation(
                  p.key,
                  parentEvent || isParentEvent
                    ? "You are already registered for this event. If you wish to change the batch, kindly cancel your existing registration and re-register for another batch."
                    : "Already Registered in another PNR"
                )
              );
              return;
            }
            if (data && data.registered === false) {
              dispatch(validatedParticipant(p.key));
              return;
            }
            dispatch(
              errorParticipantValidation(p.key, "Server or Network Error")
            );
          })
          .catch((e) => {
            dispatch(errorParticipantValidation(p.key, handleError(e)));
          });
      });
    }
  }, [
    refmatch,
    eventId,
    dispatch,
    fetchSrcmApi,
    doValidations,
    pendingVerificationPersons,
    parentEvent,
    isParentEvent,
  ]);
};

export default useNewRegistrationSubmit;
