import { isIndian } from "gatsby-plugin-hfn-profile/components/ParticipantsPanelGlobal/personFormFields";

const summaryEditableFields = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
    editable: true,
  },
  {
    name: "city_id",
    label: "City / State / Country",
    type: "city",
    editable: true,
  },
  {
    name: "age_group",
    label: "Age Group",
    type: "select",
    options: [
      4, 9, 14, 19, 24, 29, 34, 39, 44, 49, 54, 59, 64, 69, 74, 79, 84, 89, 94,
      99,
    ].map((x) => ({ name: x, label: `${x - 4}-${x}` })),
    editable: true,
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      { name: "M", label: "Male", value: "M" },
      { name: "F", label: "Female", value: "F" },
      { name: "U", label: "Unspecified", value: "U" },
    ],
    editable: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "phone",
    props: {
      required: false,
      description:
        "Please enter country code along with mobile number. example +918888888888",
    },
    editable: true,
  },
  {
    name: "email",
    label: "E-Mail",
    type: "email",
    props: {
      required: false,
    },
    editable: true,
  },
  {
    name: "citizen_of_country",
    label: "Citizenship",
    type: "srcm_country_id",
    editable: true,
  },
  {
    name: "has_oci",
    label: "Overseas Citizen of India",
    type: "toggle",
    defaultValue: false,
    editable: true,
    dynamic: (r) => {
      const currentUser = r.citizen_of_country;
      const check = currentUser ? isIndian(currentUser) : true;
      return check
        ? { props: { required: false }, hide: true }
        : {
            props: {
              required: true,
            },
          };
    },
  },
];

export default summaryEditableFields;
