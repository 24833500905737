/** @jsx jsx */
import { jsx, Text, Spinner } from "theme-ui";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DynamicForm, {
  DynamicField,
} from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm/";
import { TableForm } from "@heartfulnessinstitute/react-hfn-forms/dist/ui/TableForm";
import { compact, get, groupBy, noop, uniq } from "sites-common/utils/lodash";
import { Stack } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import copyKeys from "../../../lib/copyKeys";
import useKanhaStayConf from "./useKanhaStayConf";
import {
  IkonTextButton,
  Notice,
  SecondaryButton,
  TransparentButton,
} from "../../ParticipantsPanelGlobal/StyledButtons";
import { selectorParticipantsPanel } from "../../../state/selectors";

const time_options = [
  { name: "00-02", label: "Midnight to 2AM" },
  { name: "02-04", label: "2AM to 4AM" },
  { name: "04-06", label: "4AM to 6AM" },
  { name: "06-08", label: "6AM to 8AM" },
  { name: "08-10", label: "8AM to 10AM" },
  { name: "10-12", label: "10AM to Noon" },
  { name: "12-14", label: "Noon to 2PM" },
  { name: "14-16", label: "2PM to 4PM" },
  { name: "16-18", label: "4PM to 6PM" },
  { name: "18-20", label: "6PM to 8PM" },
  { name: "20-22", label: "8PM to 10PM" },
  { name: "22-24", label: "10PM to Midnight" },
];

const travelConfirmed = "travel_confirmed";
const airport = "airport";

const availableItems = ["other", "NA", "no-travel-plans-made"];

const availableItemsATime = ["NA", "no-travel-plans-made"];

export const forArrivalDeparture = (o) => [
  {
    name: "arrival_date",
    label: "Arrival Date",
    type: "date",
    props: {
      maxDate: o.ej_checkin_ends,
      minDate: o.ej_checkin_starts,
    },
  },

  ...(get(o, "ej_ask_transportation")
    ? [
        {
          name: "arrival_mode",
          label: o.arriving_at_location || "Arriving at Location",
          type: "select",
          options: o.arrival_mode_options,
          props: { gridWidth: 200 },
        },
        {
          name: "arrival_mode2",
          label: "Train / Flight No",
          type: "select",
          options: o.arrival_trains,
          props: { gridWidth: 200 },
          dynamic: (r) => {
            if (r?.arrival_mode === airport) {
              return {
                props: { required: false },
                type: "text",
              };
            }
            return availableItems.includes(r?.arrival_mode)
              ? {
                  props: { required: false, disabled: true },
                }
              : {
                  type: "select",
                  options: o.arrival_trains,
                  props: {},
                };
          },
        },
      ]
    : []),
  {
    name: "arrival_time",
    label: get(o, "ej_ask_transportation")
      ? "Train/Flight/Others Arrival Time"
      : "Arrival Time at Ashram",
    type: "select",
    options: time_options,
    dynamic: (r) => {
      return availableItemsATime.includes(r?.arrival_mode)
        ? {
            props: { required: false, disabled: true },
          }
        : {};
    },
  },
  {
    name: "departure_date",
    label: "Return Date",
    type: "date",
    props: {
      maxDate: o.ej_checkout_ends,
      minDate: o.ej_checkout_starts,
    },
  },
  ...(get(o, "ej_ask_transportation")
    ? [
        {
          name: "departure_mode",
          label: o.going_to_location || "Going to Location",
          type: "select",
          options: o.departure_mode_options,
          props: { gridWidth: 200 },
        },
        {
          name: "departure_mode2",
          label: "Train / Flight No",
          type: "select",
          options: o.departure_trains,
          props: { gridWidth: 200, required: false },
          dynamic: (r) => {
            if (r?.departure_mode === airport) {
              return {
                props: { required: false },
                type: "text",
              };
            }
            return availableItems.includes(r?.departure_mode)
              ? {
                  props: { required: false, disabled: true },
                }
              : {
                  type: "select",
                  options: o.departure_trains,
                };
          },
        },
      ]
    : []),
  {
    name: "departure_time",
    label: get(o, "ej_ask_transportation")
      ? "Flight/Train/Others Departure Time"
      : "Departure Time from Ashram",
    type: "select",
    options: time_options,
    dynamic: (r) => {
      return availableItemsATime.includes(r?.departure_mode)
        ? {
            props: { required: false, disabled: true },
          }
        : {};
    },
  },
];

const formFields = (o) => {
  return [
    {
      name: "visitors",
      type: "subform:multiple",
      label: "Visitor",

      subFormFields: [
        {
          name: "name",
          label: "Visitor Name",
          type: "text",
          props: { readOnly: true },
        },
        {
          name: "arrival_date",
          label: "Arrival Date",
          type: "date",
          props: {
            maxDate: o.ej_checkin_ends,
            minDate: o.ej_checkin_starts,
          },
        },

        ...(get(o, "ej_ask_transportation")
          ? [
              {
                name: "arrival_mode",
                label: o.arriving_at_location || "Arriving at Location",
                type: "select",
                options: o.arrival_mode_options,
                props: { gridWidth: 200, required: false },
              },
              {
                name: "arrival_mode2",
                label: "Train / Flight No",
                type: "select",
                options: o.arrival_trains,
                props: { gridWidth: 200, required: false },
                dynamic: (r, l) => {
                  const nthItem = l && l.length > 2 ? l[1] : 0;
                  if (r.visitors[nthItem].arrival_mode === airport) {
                    return {
                      props: { required: false },
                      type: "text",
                    };
                  }
                  return availableItems.includes(
                    r.visitors[nthItem].arrival_mode
                  )
                    ? {
                        props: { required: false, disabled: true },
                      }
                    : {
                        type: "select",
                        options: o.arrival_trains,
                        props: { required: false },
                      };
                },
              },
            ]
          : []),
        {
          name: "arrival_time",
          label: get(o, "ej_ask_transportation")
            ? "Train/Flight/Others Arrival Time"
            : "Arrival Time at Ashram",
          type: "select",
          options: time_options,
          dynamic: (r, l) => {
            const nthItem = l && l.length > 2 ? l[1] : 0;
            return availableItemsATime.includes(
              r.visitors[nthItem].arrival_mode
            )
              ? {
                  props: { required: false, disabled: true },
                }
              : {
                  props: { required: false },
                };
          },
        },
        {
          name: "departure_date",
          label: "Return Date",
          type: "date",
          props: {
            maxDate: o.ej_checkout_ends,
            minDate: o.ej_checkout_starts,
          },
        },
        ...(get(o, "ej_ask_transportation")
          ? [
              {
                name: "departure_mode",
                label: o.going_to_location || "Going to Location",
                type: "select",
                options: o.departure_mode_options,
                props: { gridWidth: 200 },
              },
              {
                name: "departure_mode2",
                label: "Train / Flight No",
                type: "select",
                options: o.departure_trains,
                props: { gridWidth: 200, required: false },
                dynamic: (r, l) => {
                  const nthItem = l && l.length > 2 ? l[1] : 0;
                  if (r.visitors[nthItem].departure_mode === airport) {
                    return {
                      props: { required: false },
                      type: "text",
                    };
                  }
                  return availableItems.includes(
                    r.visitors[nthItem].departure_mode
                  )
                    ? {
                        props: { required: false, disabled: true },
                      }
                    : {
                        type: "select",
                        options: o.departure_trains,
                        props: { required: false },
                      };
                },
              },
            ]
          : []),
        {
          name: "departure_time",
          label: get(o, "ej_ask_transportation")
            ? "Flight/Train/Others Departure Time"
            : "Departure Time from Ashram",
          type: "select",
          options: time_options,
          dynamic: (r, l) => {
            const nthItem = l && l.length > 2 ? l[1] : 0;
            return availableItemsATime.includes(
              r.visitors[nthItem].departure_mode
            )
              ? {
                  props: { required: false, disabled: true },
                }
              : {
                  props: { required: false },
                };
          },
        },
      ],
    },
  ];
};

export const form_validator = (vals, options) => {
  // const vals = { ...old_vals, ...new_vals };
  const availableOptions = ["airport", "other", "NA", "no-travel-plans-made"];
  const availableOptions2 = ["NA", "no-travel-plans-made"];

  if (
    "arrival_date" in vals &&
    "departure_date" in vals &&
    vals.departure_date < vals.arrival_date
  ) {
    return "Departure date cannot be later than arrival date";
  }

  if ("arrival_mode" in vals && !availableOptions.includes(vals.arrival_mode)) {
    if (!options?.arrival_trains?.includes(vals.arrival_mode2)) {
      return "Please fill Arrival Train / Flight No";
    }
  }
  if (
    "arrival_mode" in vals &&
    !availableOptions2.includes(vals.arrival_mode)
  ) {
    if (!vals.arrival_time) {
      return "Please fill Flight/Train/Others Arrival Time";
    }
  }

  if (
    "departure_mode" in vals &&
    !availableOptions.includes(vals.departure_mode)
  ) {
    if (!options?.departure_trains?.includes(vals.departure_mode2)) {
      return "Please fill Departure Train / Flight No";
    }
  }

  if (
    "departure_mode" in vals &&
    !availableOptions2.includes(vals.departure_mode)
  ) {
    if (!vals.departure_time) {
      return "Please fill Flight/Train/Others Departure Time";
    }
  }

  return "";
};

const form_validator_matrix = (vals, opt) => {
  return compact(
    vals.visitors
      .map((v) => form_validator(v, opt))
      .map((ret, vidx) => (ret ? `Line ${vidx + 1}: ${ret}` : ""))
  ).join("  ");
};

export const submitButton = (
  <SecondaryButton sx={{ mr: 2 }} type="submit" text="Save" />
);

export const discardButton = <TransparentButton text="Discard" />;

const formLayout = (
  renderField,
  renderErrors,
  renderLoading,
  renderSubmitBtn,
  renderDiscardBtn
) => (
  <div
    sx={{
      overflowX: "scroll",
      maxWidth: "100%",
      label: { whiteSpace: "no-wrap" },
    }}
  >
    <table>
      <tbody>
        <tr>
          <td>{renderField("arrival_date")}</td>
        </tr>
        <tr>
          <td>{renderField("arrival_mode")}</td>
        </tr>
        <tr>
          <td>{renderField("arrival_time")}</td>
        </tr>
        <tr>
          <td>{renderField("arrival_mode2")}</td>
        </tr>
        <tr>
          <td>{renderField("departure_date")}</td>
        </tr>
        <tr>
          <td>{renderField("departure_mode")}</td>
        </tr>
        <tr>
          <td>{renderField("departure_time")}</td>
        </tr>
        <tr>
          <td>{renderField("departure_mode2")}</td>
        </tr>
      </tbody>
    </table>

    <Stack sx={{ my: 4 }}>
      <div>{renderLoading}</div>
      <div>{renderErrors}</div>
      <div>
        {renderSubmitBtn} {renderDiscardBtn}
      </div>
    </Stack>
  </div>
);

function printOnly(k, v) {
  return (
    <Text sx={{ fontSize: "0.9em" }}>
      <Text sx={{ fontWeight: 300, fontSize: "0.8em" }}>{k}:</Text>
      <br />
      <Text sx={{ fontWeight: 600 }}>{v}</Text>
      <br />
    </Text>
  );
}

function printOnlyK(k, v) {
  return (
    <div sx={{ fontSize: "0.8em" }} key={k}>
      {v}
    </div>
  );
}

function AccoFormDisplay({
  stays,
  options,
  setEditMode,
  checkDate,
  isEditable,
}) {
  const [load, setLoad] = useState(false);
  const stayValues = useMemo(
    () =>
      stays.map((item) =>
        typeof item.citizen_of_country === "object"
          ? {
              ...item,
              citizen_of_country: item?.citizen_of_country?.name,
              has_oci: item.has_oci ? "Yes" : "No",
            }
          : { ...item, has_oci: item.has_oci ? "Yes" : "No" }
      ),
    [stays]
  );
  const ref = useRef(stayValues);
  useEffect(() => {
    if (ref.current !== stays) {
      setLoad(true);
    }
  }, [stays]);
  useEffect(() => {
    setLoad(false);
  }, [load]);

  if (load) {
    return <React.Fragment>Loading ... </React.Fragment>;
  }

  if (stays.length < 1) {
    return null;
  }

  const groups = groupBy(stays, (r) =>
    JSON.stringify(
      copyKeys(
        r,
        forArrivalDeparture(options).map((f) => f.name)
      )
    )
  );

  return (
    <div>
      <Stack verticalAlign="center" horizontal horizontalAlign="space-between">
        <Stack.Item>
          <div sx={{ p: 2 }}>Arrival and Departure</div>
        </Stack.Item>

        {checkDate && isEditable ? (
          <Stack.Item>
            <IkonTextButton
              title="Update Details"
              text="Edit"
              iconName="Edit"
              onClick={() => setEditMode(true)}
            />
          </Stack.Item>
        ) : (
          ""
        )}
      </Stack>

      <div sx={{ m: 3 }}>
        {Object.keys(groups).length === 1 ? (
          <DynamicForm
            formLayout={formLayout}
            formFields={forArrivalDeparture(options)}
            defaultValues={stayValues[0]}
            printOnly={printOnly}
          />
        ) : (
          <TableForm
            formFields={formFields(options)}
            defaultValues={{ visitors: stayValues }}
            printOnly={printOnlyK}
          />
        )}
      </div>
    </div>
  );
}

AccoFormDisplay.propTypes = {
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.shape({}).isRequired,
  setEditMode: PropTypes.func.isRequired,
  checkDate: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

function AccoFormEdit({
  options,
  pnr,
  people,
  meditationCenterId,
  resetEditMode,
  stays,
  isFetching,
  registerNewStays,
  updateStay,
  updateSameStay,
}) {
  const stayValues = useMemo(
    () =>
      stays.map((item) =>
        typeof item.citizen_of_country === "object"
          ? { ...item, citizen_of_country: item?.citizen_of_country?.id }
          : item
      ),
    [stays]
  );

  const [sameForEveryone, setSameForEveryone] = useState(
    stayValues.length > 0
      ? uniq(
          stayValues.map((r) =>
            JSON.stringify(
              copyKeys(
                r,
                forArrivalDeparture(options).map((f) => f.name)
              )
            )
          )
        ).length === 1
      : true
  );

  const xyz = useMemo(
    () =>
      people.map((r) => {
        const stay = stayValues.find(
          (s) => s.name + s.gender + s.email === r.name + r.gender + r.email
        );

        return {
          ...copyKeys(
            r,
            [
              "event_name",
              "name",
              "ref",
              "mobile",
              "email",
              "partner_id",
              "age_group",
              "gender",
              "city_id",
              "citizen_of_country",
              "has_oci",
              "stay_preference",
              "id",
              ...forArrivalDeparture(options).map((f) => f.name),
            ],
            { event_name: "event", id: "reg_id" }
          ),
          ...(stay || {}),

          // TEMPORARY FIX -- need to remove this line.  city_id is always expected to be set in event_rec
          city_id: r.city_id && typeof r.city_id === "number" ? r.city_id : 0,
        };
      }),
    [people, stayValues, options]
  );

  const onSave = useCallback(
    (values, sCb, fCb, sameStay = false) => {
      values.visitors
        .filter((r) => "id" in r && r.id)
        .forEach((r) => {
          const prvData = stayValues.find((s) => s.id === r.id);
          const oldD = copyKeys(prvData, [
            "arrival_mode",
            "departure_mode",
            "arrival_mode2",
            "departure_mode2",
            "arrival_date",
            "departure_date",
            "arrival_time",
            "departure_time",
            "id",
          ]);
          const newD = copyKeys(r, [
            "arrival_mode",
            "departure_mode",
            "arrival_mode2",
            "departure_mode2",
            "arrival_date",
            "departure_date",
            "arrival_time",
            "departure_time",
            "id",
          ]);
          if (JSON.stringify(oldD) !== JSON.stringify(newD)) {
            // if (travelPlanCond.includes(r?.travel_plan)) {
            //   newD.arrival_mode = null;
            //   newD.departure_mode = null;
            //   newD.arrival_mode2 = null;
            //   newD.departure_mode2 = null;
            //   newD.arrival_time = null;
            //   newD.departure_time = null;
            // }
            if (
              availableItems.includes(newD.arrival_mode) &&
              availableItemsATime.includes(newD.arrival_mode)
            ) {
              newD.arrival_mode2 = null;
              newD.arrival_time = null;
            } else if (availableItems.includes(newD.arrival_mode)) {
              newD.arrival_mode2 = null;
            }
            if (
              availableItems.includes(newD.departure_mode) &&
              availableItemsATime.includes(newD.departure_mode)
            ) {
              newD.departure_mode2 = null;
              newD.departure_time = null;
            } else if (availableItems.includes(newD.departure_mode)) {
              newD.departure_mode2 = null;
            }
            newD.travel_plan = travelConfirmed;
            updateStay(newD, noop, fCb);
          }
        });
      const newrecs = values.visitors.filter((r) => !("id" in r && r.id));
      if (newrecs.length > 0) {
        const filRec = newrecs.map((item) => {
          const i3 = { ...item };
          // if (travelPlanCond.includes(item.travel_plan)) {
          //   i3.arrival_mode = null;
          //   i3.departure_mode = null;
          //   i3.arrival_mode2 = null;
          //   i3.departure_mode2 = null;
          //   i3.arrival_date = getFormattedDate(new Date());
          //   i3.departure_date = getFormattedDate(new Date());
          //   i3.arrival_time = null;
          //   i3.departure_time = null;
          // }
          // if (travelConditions.includes(i3.arrival_mode)) {
          //   i3.arrival_mode2 = "NA";
          //   i3.arrival_time = "NA";
          // }
          // if (travelConditions.includes(i3.departure_mode)) {
          //   i3.departure_mode2 = "NA";
          //   i3.departure_time = "NA";
          // }
          i3.travel_plan = travelConfirmed;
          return i3;
        });
        registerNewStays(pnr, meditationCenterId, filRec, {});
      }
      updateSameStay(sameStay);
      sCb();
    },
    [
      stayValues,
      meditationCenterId,
      pnr,
      registerNewStays,
      updateStay,
      updateSameStay,
    ]
  );

  const onSaveSame = useCallback(
    (sameValues, sCb, fCb) => {
      onSave(
        { visitors: xyz.map((r) => ({ ...r, ...sameValues })) },
        sCb,
        fCb,
        true
      );
    },
    [xyz, onSave]
  );

  const { styles } = useSelector(selectorParticipantsPanel);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div>
      {people.length > 1 && (
        <div sx={{ m: 2 }}>
          <DynamicField
            props={{
              styles: {
                root: {
                  padding: 5,
                },
                text: {
                  color: styles.colors.primary,
                },
                thumb: {
                  backgroundColor: styles.colors.secondary,
                  "&:checked": {
                    backgroundColor: styles.colors.primary,
                  },
                  "&:hover": {
                    backgroundColor: styles.colors.tertiary,
                  },
                },
                pill: {
                  borderColor: styles.colors.well,
                  backgroundColor: styles.colors.background,
                  "&:hover": {
                    backgroundColor: styles.colors.well,
                  },
                },
              },
            }}
            name="sameForEveryone"
            label={`Are all ${people.length} visitors  arriving and leaving at the same time?`}
            value={sameForEveryone}
            onChange={setSameForEveryone}
            type="toggle"
          />
        </div>
      )}
      <div sx={{ m: 3 }}>
        {sameForEveryone ? (
          <DynamicForm
            formLayout={formLayout}
            formFields={forArrivalDeparture(options)}
            formValidator={(val) => form_validator(val, options)}
            defaultValues={
              stayValues && stayValues.length > 0
                ? copyKeys(
                    stayValues[0],
                    forArrivalDeparture(options).map((r) => r.name)
                  )
                : undefined
            }
            onSubmit={onSaveSame}
            onDiscard={() => resetEditMode(false)}
            buttons={{ submitButton, discardButton }}
          />
        ) : (
          <TableForm
            formFields={formFields(options)}
            formValidator={(a) => form_validator_matrix(a, options)}
            defaultValues={{ visitors: xyz }}
            onSubmit={onSave}
            onDiscard={() => resetEditMode(false)}
            buttons={{ submitButton, discardButton }}
          />
        )}
      </div>
    </div>
  );
}

AccoFormEdit.propTypes = {
  pnr: PropTypes.string.isRequired,
  meditationCenterId: PropTypes.number.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.shape({}).isRequired,
  resetEditMode: PropTypes.func.isRequired,
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  registerNewStays: PropTypes.func.isRequired,
  updateStay: PropTypes.func.isRequired,
  updateSameStay: PropTypes.func.isRequired,
};

function AccoForm({
  eventOptions,
  pnr,
  people,
  meditationCenterId,
  stays,
  registerNew: registerNewStays,
  updateReg: updateStay,
  cancelRegs: cancelStays,
  isFetching,
  checkDate,
  isEditable,
  updateSameStay,
}) {
  const [editMode, setEditMode] = useState(false);
  const stayKeys = isFetching
    ? []
    : stays.map((s) => s.name + s.age_group + s.gender);
  const missing = people.filter(
    (p) => !stayKeys.includes(p.name + p.age_group + p.gender)
  );
  const stayValue = stays.filter((item) => item.status !== "cancelled");
  const { conf } = useKanhaStayConf();

  const check = stayValue.length < 1;

  const options = {
    ...eventOptions,
    ...(conf || {}),
  };

  useEffect(() => {
    if (!!people && !!stays) {
      const peopleKeys = people
        .filter((item) => item.status !== "cancelled")
        .map((item) => `${item.name} ${item.email} ${item.mobile}`);
      const cancelStayIds = stays
        .filter(
          (s) =>
            !peopleKeys.includes(`${s.name} ${s.email} ${s.mobile}`) &&
            s.status !== "cancelled"
        )
        .map((s) => s.id);
      if (cancelStayIds) {
        cancelStays(cancelStayIds);
      }
    }
  }, [people, stays, cancelStays]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div key={people.length}>
      {missing.length > 0 && checkDate && (
        <Notice type="warning">
          <h3 className="warning_text">
            Newly added {missing.length} Member
            {missing.length > 1 ? "s" : ""} - (
            {missing.map((p) => p.name).join(", ")}), Transport information
            needs to be updated. Please click on Edit below and save/update the
            details to proceed forward.
          </h3>
        </Notice>
      )}

      {((!editMode && !check) || !checkDate) && (
        <AccoFormDisplay
          stays={stayValue}
          options={options}
          setEditMode={setEditMode}
          checkDate={checkDate}
          isEditable={isEditable}
        />
      )}
      {(editMode || check) && checkDate && isEditable && (
        <AccoFormEdit
          options={options}
          resetEditMode={setEditMode}
          pnr={pnr}
          people={people}
          meditationCenterId={meditationCenterId}
          stays={stayValue}
          isFetching={isFetching}
          registerNewStays={registerNewStays}
          updateStay={updateStay}
          updateSameStay={updateSameStay}
        />
      )}
    </div>
  );
}

AccoForm.defaultProps = {
  isEditable: true,
};

AccoForm.propTypes = {
  pnr: PropTypes.string.isRequired,
  meditationCenterId: PropTypes.number.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  eventOptions: PropTypes.shape({}).isRequired,
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  cancelRegs: PropTypes.func.isRequired,
  registerNew: PropTypes.func.isRequired,
  updateReg: PropTypes.func.isRequired,
  updateSameStay: PropTypes.func.isRequired,
  checkDate: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
};

export default AccoForm;
