/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx, Spinner, Text, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Stack } from "office-ui-fabric-react";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import DynamicForm, {
  DynamicField,
} from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import strAgeGroup from "gatsby-plugin-hfn-profile/lib/str-age-group";
import useMySrcmEvent from "gatsby-plugin-hfn-profile/components/SrcmModels/useMySrcmEvent";
import {
  BadgeLabel,
  IkonTextButton,
  SecondaryButton,
  TransparentButton,
} from "gatsby-plugin-hfn-profile/components/ParticipantsPanelGlobal/StyledButtons";
import { selectorParticipantsPanel } from "gatsby-plugin-hfn-profile/state/selectors";
import { get } from "sites-common/utils/lodash";
import summaryEditableFields from "../../data/summaryFields";

const submitButton = (
  <SecondaryButton sx={{ mr: 2 }} type="submit" text="Update" />
);

const discardButton = <TransparentButton text="Cancel" />;

// function printOnly(k, v) {
//   return (
//     <Text sx={{ fontSize: "0.9em" }}>
//       <Text sx={{ fontWeight: 300, fontSize: "0.8em" }}>{k}:</Text>
//       <br />
//       {v}
//       <br />
//     </Text>
//   );
// }

function PnrSummaryComponent({
  person,
  idx,
  inProgress,
  listSelected,
  onSelectToggle,
  onUpdate,
  validation,
  checkDate,
  rolesList,
}) {
  const { styles } = useSelector(selectorParticipantsPanel);

  const {
    age_group,
    event_name,
    gender,
    record_type,
    ref,
    email,
    mobile,
    status,
    stay_preference,
  } = person;
  const display =
    ref !== "B99999999" && ref !== "B99999998" ? ref : email || mobile;

  const cancelled = status === "cancelled";
  const { data } = useMySrcmEvent({ name: event_name });

  const customConfig =
    data && data.event_json && data.event_json.custom_config_json
      ? JSON.parse(data.event_json.custom_config_json)
      : {};
  const editableFieldsList = customConfig?.p2fieldmap?.fields
    ? [...summaryEditableFields, ...customConfig?.p2fieldmap?.fields]
    : [...summaryEditableFields];
  const editableFields = editableFieldsList?.filter((f) => f.editable);

  const [editmode, { setTrue: setEditmode, setFalse: discardEditmode }] =
    useMyBoolean(false);
  const key = `${person.id}`;
  const selected = get(listSelected, key, false);
  const toggle = useCallback(
    (value) => {
      onSelectToggle((x) => ({ ...x, [key]: value }));
    },
    [onSelectToggle, key]
  );

  const formLayout = (
    renderField,
    renderErrors,
    renderLoading,
    renderSubmitBtn,
    renderDiscardBtn
  ) => (
    <div>
      <Grid columns={[1, 2, 2, 4]}>
        {editableFields.map((item) => (
          <div key={item.name}>{renderField(item.name)}</div>
        ))}
      </Grid>
      <Stack sx={{ my: 4 }}>
        <div>{renderLoading}</div>
        <div>{renderErrors}</div>
        <div sx={{ display: "flex" }}>
          <div>{renderSubmitBtn}</div>
          <div sx={{ ml: 2 }}>{renderDiscardBtn}</div>
        </div>
      </Stack>
    </div>
  );

  return (
    <div
      sx={{
        border: "solid 1px",
        borderColor: styles.colors.tertiary,
        backgroundColor: "white",
        p: 2,
        m: 2,
      }}
      key={idx}
    >
      <Stack
        tokens={{ childrenGap: 10 }}
        horizontalAlign="space-between"
        horizontal
      >
        <Stack.Item>
          <Stack horizontal>
            {!!onSelectToggle && (
              <DynamicField
                props={{
                  disabled: cancelled,

                  styles: {
                    root: {
                      padding: 5,
                    },
                    checkbox: {
                      background: styles.colors.background,
                      "&:hover": {
                        opacity: 0.5,
                      },
                      "&:enabled": {
                        opacity: 0.5,
                        background: styles.colors.background,
                      },
                    },
                    checkmark: {
                      color: styles.colors.text,

                      "&:hover": {
                        color: styles.colors.primary,
                        opacity: 0.5,
                      },
                    },
                  },
                }}
                type="checkbox"
                name="person"
                label=""
                value={selected}
                onChange={toggle}
              />
            )}
            <Stack tokens={{ childrenGap: 5 }}>
              <Stack.Item>
                <Text sx={{ fontSize: "1.3em" }}>{person.name}</Text>
              </Stack.Item>
              <Stack.Item>
                <Text
                  title={display}
                  sx={{
                    display: "inline-block",
                    fontSize: "0.8em",
                    maxWidth: "150px",
                    marginRight: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {display}
                </Text>
                {age_group && (
                  <BadgeLabel bg="secondary">
                    {gender} {strAgeGroup(age_group)}
                  </BadgeLabel>
                )}
                {record_type === "t" && (
                  <BadgeLabel bg="text">trainer</BadgeLabel>
                )}
                {stay_preference && (
                  <BadgeLabel sx={{ mx: 3 }}>{stay_preference}</BadgeLabel>
                )}
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack.Item>

        <Stack.Item>
          <Stack verticalAlign="space-between">
            {idx !== undefined && (
              <div
                sx={{
                  textAlign: "right",
                  opacity: "0.75",
                  fontSize: "1em",
                  color: styles.colors.well,
                }}
              >
                {idx}
              </div>
            )}
            {inProgress && <Spinner />}
            {!inProgress && (
              <BadgeLabel
                bg={cancelled ? styles.colors.error : styles.colors.primary}
                sx={{ mr: 3 }}
              >
                {status}
              </BadgeLabel>
            )}
          </Stack>
        </Stack.Item>
      </Stack>

      {!!editableFields && editableFields.length > 0 && (
        <div
          sx={{
            mt: 2,
            borderTop: "solid 1px",
            ...(editmode ? { p: 2, bg: styles.colors.background } : {}),
            borderColor: styles.colors.background,
          }}
        >
          <Stack horizontal horizontalAlign="space-between">
            {editmode ? (
              <Stack.Item
                grow
                sx={{
                  py: 2,
                }}
              >
                <DynamicForm
                  key={editmode ? "1" : "0"}
                  formFields={editableFields}
                  formLayout={formLayout}
                  defaultValues={{ ...person }}
                  // printOnly={!editmode ? printOnly : undefined}
                  onSubmit={onUpdate}
                  onDiscard={discardEditmode}
                  formValidator={validation}
                  buttons={{ submitButton, discardButton }}
                />
              </Stack.Item>
            ) : (
              ""
            )}

            {!checkDate ||
              (!cancelled && !editmode && rolesList.includes("info_center") && (
                <Stack.Item>
                  <IkonTextButton
                    title="Update Details"
                    text="Edit"
                    iconName="Edit"
                    onClick={setEditmode}
                  />
                </Stack.Item>
              ))}
          </Stack>
        </div>
      )}
    </div>
  );
}

PnrSummaryComponent.propTypes = {
  person: PropTypes.instanceOf(Object).isRequired,
  idx: PropTypes.number,
  inProgress: PropTypes.bool,
  validation: PropTypes.func,
  listSelected: PropTypes.shape({}),
  onSelectToggle: PropTypes.func,
  onUpdate: PropTypes.func,
  checkDate: PropTypes.bool.isRequired,
  rolesList: PropTypes.instanceOf(Array).isRequired,
};

PnrSummaryComponent.defaultProps = {
  idx: undefined,
  inProgress: false,
  validation: undefined,
  onUpdate: undefined,
  listSelected: {},
  onSelectToggle: undefined,
};

export default React.memo(PnrSummaryComponent);
